import React, {useEffect} from 'react';
import {useHelpMutation} from "../../../store/APIs/crosswordApi";
import {TTipType} from "../../../models";
import {
  selectCurrentConfig,
  selectCurrentDiamonds,
  selectCurrentLocalLevel,
  selectCurrentTips,
  selectCurrentUserData,
  setDiamondsCount,
  setTipCount
} from "../../../store/Slices/userSlice";
import {useAppDispatch, useAppSelector} from "../../../store/store";
import Diamond from "../../../assets/svg/diamond.svg"
import {motion} from 'framer-motion';
import {setCurrentPopout} from "../../../store/Slices/popoutsSlice";
import {actionMap, iconsMap, sizeMap} from "./constants";
import {PreloadImages} from "../../../assets/images";
import {selectCurrentTrainingProgress, setIsBlurred, setIsUsed} from "../../../store/Slices/connectionHelperSlice";
import {selectCurrentGameIsOver, selectCurrentHammerIsActivated} from "../../../store/Slices/crosswordSlice";
import * as amplitude from "@amplitude/analytics-browser";

export interface ITipProps {
  type: TTipType
}

function Tip({type, ...props}: ITipProps & React.HTMLProps<HTMLDivElement>) {
  const [useTip, {data, isLoading, reset, error}] = useHelpMutation()
  const dispatch = useAppDispatch()
  const userData = useAppSelector(selectCurrentUserData)
  const currentTips = useAppSelector(selectCurrentTips)
  const currentConfig = useAppSelector(selectCurrentConfig)
  const currentDiamonds = useAppSelector(selectCurrentDiamonds)
  const currentTrainingProgress = useAppSelector(selectCurrentTrainingProgress)
  const isUsed = currentTrainingProgress?.[type].isUsed
  const priceMap: Record<TTipType, number> = {
    hammer: currentConfig?.hammer_price ? currentConfig?.hammer_price : 0,
    lamp: currentConfig?.lamp_price ? currentConfig?.lamp_price : 0
  }
  const currentPrice = priceMap[type]
  const currentLevel = useAppSelector(selectCurrentLocalLevel)
  const hammerIsActivated = useAppSelector(selectCurrentHammerIsActivated)
  const gameIsOver = useAppSelector(selectCurrentGameIsOver)

  useEffect(() => {
    if (error) {
      reset()
      dispatch(setCurrentPopout({
        name : "error",
        props : {
          requestName : "useTip"
        }
      }))
    }
  }, [error]);

  useEffect(() => {
    if (isUsed && !hammerIsActivated) {
      dispatch(setIsBlurred(false))
    }
  }, [hammerIsActivated, isUsed]);

  const onTipClick = () => {
    if (!userData || !currentLevel) return;
    if (currentLevel === 3 && type === "hammer" && !isUsed) {
      dispatch(actionMap['hammer']())
      return;
    } else if (currentLevel === 5 && type === "lamp" && !isUsed) {
      dispatch(actionMap['lamp']())
      dispatch(setIsUsed({
        type: "lamp"
      }))
      const query = {
        tutorial_step : 6
      }
      amplitude.track("Tutorial Step", query);
      return;
    }
    if (currentDiamonds && (currentDiamonds < currentPrice) && !currentTips?.[type]) {
      dispatch(setCurrentPopout({
        name : "advertising"
      }));
    } else {
      useTip({
        type,
        platform: "vk",
        user_id: String(userData.user_id)
      })
    }
  }
  useEffect(() => {
    if (!data || !currentLevel) return;
    if (data.success) {
      if (data.count !== undefined) {
        dispatch(setTipCount({type, count: data.count}))
        dispatch(actionMap[type]())
      } else if (data.diamonds !== undefined) {
        dispatch(setDiamondsCount(data.diamonds))
        dispatch(actionMap[type]())
      }

      const query = {
        level_num: currentLevel,
        booster: type
      }
      amplitude.track("UseBooster", query)
      if (type === "hammer") {
        dispatch(setIsBlurred(true))
      }
    } else {
      dispatch(setCurrentPopout({
        name: "advertising"
      }));
    }
  }, [data]);
  if (!currentTips) return null;
  const currentTipCount = currentTips[type]
  const FingerHelper = () => (
    <>
      {
        (
          (
            (currentLevel === 3 && type === "hammer" && !hammerIsActivated)
            || (currentLevel === 5 && type === "lamp")
          ) && !isUsed
        ) &&
          <div className={`pointer-events-none w-full h-full relative z-10`}>
              <motion.div
                  className={`absolute -bottom-8 -right-8`}
                  animate={{
                    scale: [1, 1.1, 1],
                    transition: {
                      duration: 1,
                      repeat: Infinity,
                      ease: "easeInOut"
                    }
                  }}
              >
                  <img src={PreloadImages.Finger} className={`w-[64px]`} alt=""/>
              </motion.div>
          </div>}
    </>
  )
  return (
    <motion.button
      className={`bg-transparent border-0 ${hammerIsActivated && "grayscale"} ${props.className}`}
      initial={{scale: 1}}
      whileTap={{scale: 0.95}}
      disabled={isLoading || hammerIsActivated || gameIsOver}
      onClick={onTipClick}
    >
      <div
        className={`w-16 h-16 bg-white flex items-center justify-center rounded-full`}
        style={{
          boxShadow: "1px 3px 6px rgba(0, 0, 0, 0.25), inset 0px 0px 1px 1px rgba(126, 107, 93, 0.37)"
        }}
      >
        <div
          className={`w-14 flex items-center justify-center h-14 bg-[#00D1FF] rounded-full`}
          style={{
            boxShadow: "inset 0px 0px 1px 1px #0088D5, inset 0px -1px 4px 3px #00A1FC, inset 0px 1px 2px 8px #3DD7FF"
          }}
        >
          <FingerHelper/>
          <img src={iconsMap[type]} alt=""
               className={`absolute top-1/3 left-1/2 -translate-x-[32px] -translate-y-[30px] ${sizeMap[type]}`}/>
          <div className={`flex items-center absolute space-x-1 px-2 bg-white rounded-2xl h-[20px] -bottom-1.5`}
               style={{
                 boxShadow: "1px 3px 6px rgba(0, 0, 0, 0.25), inset 0px 0px 1px 1px rgba(126, 107, 93, 0.37)"
               }}
          >
            {currentTipCount === 0 &&
                <>
                    <img src={Diamond} alt="" className={`w-[18px]`}/>
                    <div className={`translate-y-[1px] text-chocolate font-bold`}>
                      {priceMap[type]}
                    </div>
                </>
            }
            {currentTipCount > 0 &&
                <div className={`translate-y-[1px] text-chocolate font-bold`}>
                  {currentTips?.[type]}
                </div>
            }

          </div>
        </div>
      </div>
    </motion.button>
  );
}

export default Tip;