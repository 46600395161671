import React, {CSSProperties, HTMLProps, ReactNode, useEffect, useRef, useState} from 'react';
import CloseIcon from "../../UI/CloseIcon";
import {useAppDispatch, useAppSelector} from "../../../store/store";
import {setCurrentPopout} from "../../../store/Slices/popoutsSlice";
import useOutsideClick from "../../../hooks/useClickOutside";
import PNGs from "../../../assets/images";
import FirstStar from "../../UI/Icons/FirstStar";
import SecondStar from "../../UI/Icons/SecondStar";
import ThirdStar from "../../UI/Icons/ThirdStar";
import {useRatingQuery} from "../../../store/APIs/crosswordApi";
import {selectCurrentUserData} from "../../../store/Slices/userSlice";

function RatingPopout({}) {
  const ref = useRef<HTMLDivElement>(null)
  const dispatch = useAppDispatch()
  const userData = useAppSelector(selectCurrentUserData)
  const onCloseClick = () => {
    dispatch(setCurrentPopout({
      name : "hidden"
    }))
  }

  const [currentTab, setCurrentTab] = useState<"week" | "all">("all");
  useOutsideClick(ref, onCloseClick)

  const {data: ratingData, isFetching} = useRatingQuery({
    period: currentTab,
    platform: "vk",
    user_id: String(userData?.user_id)
  }, {
    skip: !userData,
    refetchOnMountOrArgChange: true
  })

  let usersContent;

  if (ratingData && !isFetching) {
    usersContent = ratingData.users.map((user, index) =>
      <RatingItem
        key={`rating_item_${user.uid}`}
        index={index + 1}
        photo={user.photo}
        name={user.name}
        level={user.count}
        isMy={user.uid === String(userData?.user_id)}
      />
    );
  } else {
    usersContent = Array(20).fill(0).map((dick, index) =>
      <RatingItemSkeleton key={`rating_skeleton_${index}`} index={index}/>
    )
  }

  return (
    <div
      className={`h-full box-border pt-[13vh] relative bg-black/70`}
    >
      <div
        ref={ref}
        style={{
          boxShadow: "0px -3px 4px 3px #BC7126 inset, 0px 6px 4px 0px #FBC38D inset, 0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
          border: "2px solid #B46112"
        }}
        className={
          `rating_container w-[85%] bg-[#E9A460] z-[0] rounded-[48px] h-[80%] box-border pt-[15%] pt-[91px] mx-auto relative`
        }
      >
        <div className={`absolute left-1/2 -translate-x-1/2 -top-8`}>
          <div className={`relative`}>
            <img src={PNGs.RatingPopoutSubstrate} className={`w-[280px]`} alt=""/>
            <p style={{
              textShadow : "0px 3.375px 4.5px rgba(0, 0, 0, 0.35)",
              top : "calc(33% - 2px)"
            }} className={`text-[24px] font-bold whitespace-nowrap absolute left-1/2 -translate-x-1/2 -translate-y-1/2`}>Доска лидеров</p>
          </div>
        </div>

        <CloseItem onClick={onCloseClick}/>
        <div className={`h-[100%] w-full relative`}>
          <div
            style={{
              boxShadow: "0px -3px 4px 3px #BC7126 inset, 0px 6px 4px 0px #FBC38D inset, 0px 4px 4px 0px rgba(0, 0, 0, 0.25)"
            }}
            className={`border-solid relative z-[1] py-8 px-3 box-border border-[#B46112] border-l-0 border-r-0 border-b-0 border-t-2 h-[100%] w-full bg-[#FFBD70] rounded-[48px] relative`}
          >
            <RatingList className={`relative`}>
              {
                !isFetching &&
                  <RatingItem
                      name={`${userData?.name}`}
                      index={ratingData?.my.position}
                      level={ratingData?.my.count}
                      photo={`${userData?.photo}`}
                      isMy={true}
                  />
              }
              {usersContent}
            </RatingList>
          </div>
          {/* Основная область*/}
          <RatingTab
            menuPosition={"left"}
            onClick={() => {
              setCurrentTab("all")
            }}
            isSelected={currentTab === "all"}
          >
            <div className={`text-[#AB423C] text-xl font-bold`}>Всё время</div>
          </RatingTab>
          <RatingTab
            menuPosition={"right"}
            onClick={() => {
              setCurrentTab("week")
            }}
            isSelected={currentTab === "week"}
          >
            <div className={`text-[#AB423C] text-xl font-bold`}>За неделю</div>
          </RatingTab>

        </div>
      </div>
    </div>
  );
}


export interface IRatingListProps {
  children: ReactNode
}

const RatingList = ({children, ...rest}: IRatingListProps & HTMLProps<HTMLDivElement>) => {
  return (
    <div
      {...rest}
      style={{
        boxShadow: "0px 0px 10px 3px #E18F26 inset",
        ...rest.style
      }}
      className={`rating-container overflow-y-scroll rounded-[29px] h-full box-border pl-2 pr-5 bg-[#FFB54F] py-5 ${rest.className}`}
    >
      <div>

      </div>
      <div className={`flex flex-col space-y-0.5 `}>
        {children}
      </div>
    </div>
  )
}

export interface IRatingTabProps {
  menuPosition: "left" | "right"
  children?: ReactNode
  isSelected?: boolean
  onClick?: (...args: any) => any
}


export interface IRatingItem {
  name: string
  index: number | undefined
  level: number | undefined
  photo: string
  isMy?: boolean
}

const RatingItem = ({name, level, index, photo, isMy}: IRatingItem) => {
  return (
    <div
      style={{
        boxShadow: isMy
          ? "0px 3px 2px 0px #BAE9FF inset, 0px -5px 4px 0px #60CCFF inset"
          : "0px 3px 2px 0px #FFF0D7 inset, 0px -5px 4px 0px #F4C371 inset"
      }}
      className={`border-solid pl-1.5 pr-2.5 box-border ${
        isMy ? "border-[#0093E6]" : "border-[#EB9012]"} ${
        isMy ? "bg-[#7FD6FF]" : "bg-[#FEE6BD]"
      } border-[1.5px] py-1.5 border grid grid-cols-[1fr_1.6fr_2.5fr_3fr] rounded-[16px] gap-x-1 items-center w-full`}>
      <div
        style={{
          textShadow: "-1px -1px 0 #C15C00, 1px -1px 0 #C15C00, -1px 1px 0 #C15C00, 1px 1px 0 #C15C00"
        }}
        className={`relative`}>
        {index === 1 &&
            <FirstStar size={34} className={`absolute z-[1] top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2`}/>}
        {index === 2 &&
            <SecondStar size={34} className={`absolute z-[1] top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2`}/>}
        {index === 3 &&
            <ThirdStar size={34} className={`absolute z-[1] top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2`}/>}
        <div className={`text-[12px] absolute top-1/2 left-1/2 z-[2] -translate-y-1/2 -translate-x-1/2`}>
          {index}
        </div>
      </div>
      <div className={`w-full flex items-center justify-center`}>
        <img
          src={photo}
          alt={"user photo avatar"}
          style={{
            border: "2px solid #C15C00"
          }}
          className={`w-full max-w-[64px] box-border rounded-xl aspect-square`}
        />
      </div>
      <div className={`flex flex-col flex-wrap truncate`}>
        {name.split(" ").map((word, index) => (
          <div
            style={{
              "textShadow": "-1px -1px 0 #C15C00, 1px -1px 0 #C15C00, -1px 1px 0 #C15C00, 1px 1px 0 #C15C00",
            } as CSSProperties}
            key={`word_${index}`}
            className={`truncate w-full text-[12px] sm:text-[16px] font-bold`}
          >
            {word}
          </div>
        ))}
      </div>
      <div className={`flex justify-end`}>
        <div
          style={{
            boxShadow: "0px -2px 4px 0px #FFC068 inset"
          }}
          className={`w-full relative pt-1 pb-0.5 pl-4 pr-2 bg-[#FFD69D] rounded-xl`}
        >
          <img src={PNGs.ChampionCup} alt="rating cup" className={`absolute -left-6 top-1/2 -translate-y-1/2 w-10`}/>
          <p
            style={{
              "textShadow": "-1px -1px 0 #C15C00, 1px -1px 0 #C15C00, -1px 1px 0 #C15C00, 1px 1px 0 #C15C00",
            }}
            className={`text-[12px] font-bold`}
          >{level} ур.</p>
        </div>
      </div>
    </div>
  )
}

const RatingItemSkeleton = ({index}: { index: number }) => {
  return (
    <div
      style={{
        boxShadow: "0px 3px 2px 0px #FFF0D7 inset, 0px -5px 4px 0px #F4C371 inset"
      }}
      className={`border-solid pl-1.5 pr-2.5 box-border border-[#EB9012] border-[1.5px] py-1.5 border grid grid-cols-[1fr_1.6fr_1.7fr_4fr] bg-[#FEE6BD] rounded-[16px] gap-x-1 items-center w-full`}>
      <div
        style={{
          textShadow: "-1px -1px 0 #C15C00, 1px -1px 0 #C15C00, -1px 1px 0 #C15C00, 1px 1px 0 #C15C00"
        }}
        className={`relative`}>
        {index === 0 && <FirstStar key={`${index}_star`} size={34}
                                   className={`absolute z-[1] top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2`}/>}
        {index === 1 && <SecondStar key={`${index}_star`} size={34}
                                    className={`absolute z-[1] top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2`}/>}
        {index === 2 && <ThirdStar key={`${index}_star`} size={34}
                                   className={`absolute z-[1] top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2`}/>}
        <div className={`text-[12px] absolute top-1/2 left-1/2 z-[2] -translate-y-1/2 -translate-x-1/2`}>
          {index + 1}
        </div>
      </div>
      <div
        style={{
          border: "2px solid #C15C00"
        }}
        className={`w-full box-border rounded-xl bg-amber-500 animate-pulse aspect-square`}
      />
      <div className={`flex flex-col flex-wrap space-y-1`}>
        {(["name", "surname"]).map((word, index) => (
          <div key={`index_${word}`} className={`w-10 h-2 bg-amber-500 animate-pulse rounded-2xl`}/>
        ))}
      </div>
      <div className={`flex justify-end`}>
        <div
          style={{
            boxShadow: "0px -2px 4px 0px #FFC068 inset"
          }}
          className={`w-fit relative py-1 pl-4 pr-2 bg-[#FFD69D] rounded-xl`}
        >
          <img src={PNGs.ChampionCup} alt="rating cup" className={`absolute -left-6 top-1/2 -translate-y-1/2 w-10`}/>
          <div className={`w-14 h-2 bg-amber-500 animate-pulse rounded-2xl`}/>
          {/*Fake pulsating div*/}
        </div>
      </div>
    </div>
  )
}

const RatingTab = ({menuPosition, children, isSelected, onClick}: IRatingTabProps) => {
  const substrateXPos = menuPosition === "left" ? {left: "calc(4% + 2px)"} : {right: "calc(4% + 2px)"}
  const borderXPos = menuPosition === "left" ? {left: "4%"} : {right: "4%"}
  return (
    <>
      {isSelected ?
        <div
          style={{
            ...substrateXPos,
            top: "calc(-1 * (2.5rem - 2px))",
          }}
          className={`rounded-t-[38px] box-border z-[2] absolute h-16 w-[44.5%] bg-[#FFBC70]`}
        >
          <div className={`z-[1] w-full mt-2 text-center`}>{children}</div>
        </div> // Бордер
        : <div
          style={{
            ...substrateXPos,
            top: "calc(-1 * (2.5rem - 2px))",
          }}
          onClick={onClick}
          className={`rounded-t-[38px] box-border absolute flex items-start h-16 w-[44.5%] bg-[#FFBC70]`}
        >
          <div className={`z-[1] w-full mt-2 text-center`}>{children}</div>
        </div>}
      <div
        style={{
          ...borderXPos,
          border: "2px solid #B46112"
        }}
        className={`rounded-t-[38px] absolute -top-10 h-14 z-[0] w-[44.5%] ${isSelected ? "bg-[#FFBC70]" : "bg-[#E49549]"}`}
      >
      </div>
    </>
  )
}

const CloseItem = ({onClick}: { onClick: (...args: any) => any }) => (
  <div
    onClick={onClick}

    className={`absolute p-2 -right-2 -top-2 flex items-center justify-center z-10`}>
    <div
      style={{
        boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.25), 0px -1px 3px 1px #BC7026 inset, 0px 2px 2px 0px #FFC995 inset"
      }}
      className={`relative w-10 h-10 bg-[#E9A460] rounded-full`}
    >
      <CloseIcon className={`absolute left-1/2 top-1/2 -translate-y-1/2 -translate-x-1/2`} size={17}
                 color={`#814C17`}/>
    </div>
  </div>
)

export default RatingPopout;