import ExternalPlatformControllerInstance, {IOpenInterstitialAdResult} from "./abstract";
import VKConnect, {__DEV, __DEV_ID} from "../../VKConnect";
import {setLaunchParams, setNotificationAllowed, setUser} from "../../../store/Slices/userSlice";
import {Dispatch} from "redux";
import {TUserData} from "../../../hooks/useInitExternalPlatform";
import VKWebApp from "../../VKWebApp";

class VKPlatformControllerInstance implements ExternalPlatformControllerInstance {
  constructor() {
  }

  async init(dispatch: Dispatch) {
// Init vk.ts  Mini App
    const user = await VKConnect.send('VKWebAppGetUserInfo');
    if (!__DEV) {
      await VKConnect.send('VKWebAppGetLaunchParams')
        .then((data) => {
          if (data.vk_app_id) {
            dispatch(setNotificationAllowed(!!data.vk_are_notifications_enabled))
            dispatch(setLaunchParams(data))
          }
        })
        .catch((error) => {
          // Ошибка
          console.log(error);
        });
    }
    user.id = __DEV ? __DEV_ID : user.id
    const {id, first_name, last_name, photo_200} = user;
    const result: TUserData = {
      platform: "vk",
      user_id: String(id),
      name: `${first_name} ${last_name}`,
      photo: photo_200
    }
    return result
  }

  updateLoadingProgress(percentage : number) {

  }

  checkAdAvailable(type : "reward" | "interstitial"): Promise<boolean> {
    return new Promise((res, rej) => {
      VKWebApp.useCheckNativeAds(type).then(data => {
        if (data.result) {
          res(data.result)
        } else {
          rej(data)
        }
      }).catch(error => {
        rej(error)
      })
    })
  }

  showAd(): Promise<IOpenInterstitialAdResult> {
    return VKWebApp.useShowNativeAds("interstitial")
  }
}

export default VKPlatformControllerInstance;