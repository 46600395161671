import React, {MouseEventHandler, useRef} from 'react';
import Lamp from "../../../assets/svg/lamp-turn-on.svg";
import Hammer from "../../../assets/svg/hammer.svg";
import {useAppDispatch, useAppSelector} from "../../../store/store";
import {
  ClaimRewardPayload,
  claimRewards, selectCurrentDiamonds, selectCurrentTips,
  selectCurrentUserData
} from "../../../store/Slices/userSlice";
import {Button} from "../../UI";
import "./style.css"
import VKWebApp from "../../../utility/VKWebApp";
import CloseIcon from "../../UI/CloseIcon";
import {setCurrentPopout, setCurrentWarningPopout} from "../../../store/Slices/popoutsSlice";
import useOutsideClick from "../../../hooks/useClickOutside";
import * as amplitude from "@amplitude/analytics-browser";
import {useAdvertisingMutation} from "../../../store/APIs/crosswordApi";
import sha256 from "../../../utility/sha256";
import VKConnect from "../../../utility/VKConnect";
import {externalPlatformController} from "../../../index";

export interface IAdvertisingPopoutProps {

}

function AdvertisingPopout({}: IAdvertisingPopoutProps) {
  const popoutWidth = innerWidth >= 648 ? 648 : "100vw"
  const dispatch = useAppDispatch()
  const ref = useRef<HTMLDivElement>(null)
  const onCloseClick: MouseEventHandler = (e) => {
    dispatch(setCurrentPopout({
      name : "hidden"
    }))
  }
  useOutsideClick(ref, onCloseClick)
  return (
    <div style={{width: popoutWidth}} className={`h-full bg-[#100500]/80 box-border pt-32 z-10`}>
      <div
        ref={ref}
        style={{boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25), inset -3px -5px 4px #BC7026, inset 3px -5px 4px #BC7026, inset 0px 3px 2px #FFC995, inset 0px -5px 4px #BC7026"}}
        className={`w-[80%] bg-[#E9A460] rounded-[48px] box-border pt-2 mx-auto px-3 pb-5 relative`}
      >
        <div
          onClick={onCloseClick}

          className={`absolute p-2 -right-2 -top-2 flex items-center justify-center z-10`}>
          <div
            style={{
              boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.25), 0px -1px 3px 1px #BC7026 inset, 0px 2px 2px 0px #FFC995 inset"
            }}
            className={`relative w-10 h-10 bg-[#E9A460] rounded-full`}
          >
            <CloseIcon className={`absolute left-1/2 top-1/2 -translate-y-1/2 -translate-x-1/2`} size={17} color={`#814C17`}/>
          </div>
        </div>
        <p className={`text-[22px] my-0 mb-4 text-center text-white drop-shadow font-bold`}>Выбери подсказку</p>
        <div
          style={{boxShadow: "0px -1px 4px #D28030, 0px 2px 4px #D28030"}}
          className={`bg-[#FEE6BD] py-6 rounded-[33px] grid grid-cols-1 gap-y-3 px-4`}
        >
          <AdvertisingItem iconTop={`-left-1/2 -top-1`} iconSize={`w-14`} src={Lamp} place={"lamp"} count={1}/>
          <AdvertisingItem iconTop={`-left-1/2`} iconSize={`w-16`} src={Hammer} place={"hammer"} count={1}/>
        </div>
      </div>
    </div>
  );
}

export interface IAdvertisingItemProps {
  src: string
  count: number
  iconLeft?: string
  iconTop?: string
  iconSize?: string
  place : string
}

function AdvertisingItem({src, count, iconTop, iconLeft, place, iconSize}: IAdvertisingItemProps) {
  const [useAdvertising, {data}] = useAdvertisingMutation()
  const userData = useAppSelector(selectCurrentUserData)
  const tips = useAppSelector(selectCurrentTips)
  const diamonds = useAppSelector(selectCurrentDiamonds)
  const dispatch = useAppDispatch()
  const onClick = async () => {
    if (!tips || typeof diamonds === "undefined") return;
    const type = place as "hammer" | "lamp"
    const ad_id = await sha256(`${tips.lamp}${tips.hammer}${diamonds}${type}`)

    const showNoAdsWarning = () => {
      dispatch(setCurrentWarningPopout({
        heading : "Реклама закончилась :(",
        article : "К сожалению, у нас сейчас нет рекламы для показа, попробуйте позже!",
      }))
    }

    const isAvailable = await externalPlatformController.checkAdAvailable("reward");
    if (isAvailable) {
      externalPlatformController.showAd("reward").then(data => {
        if (data.result) {
          const query = {
            place : place,
            type : "interstitial"
          }
          amplitude.track("WatchedAd", query)
          if (!userData) return;
          useAdvertising({
            type : place as "hammer" | "lamp",
            user_id : String(userData.user_id),
            ad_id : ad_id,
            platform : "vk"
          }) // Здесь
          dispatch(setCurrentPopout({
            name : "hidden"
          }))
          const reward : ClaimRewardPayload = {
            diamonds : 0,
            hammer : 0,
            lamp : 0
          }
          // @ts-ignore
          reward[place]++
          dispatch(claimRewards(reward))
        } else {
          showNoAdsWarning()
        }
      }).catch(error => {
        showNoAdsWarning()
      })
    } else {
      showNoAdsWarning()
    }
  }

  return (
    <div
      style={{boxShadow: "inset 0px -1px 4px 3px #F5C47F"}}
      className={`flex items-center justify-between bg-[#F2CB95] rounded-[18px] pl-8 pr-4`}
    >
      <div className={`py-4`}>
        <div
          style={{boxShadow: "inset 0px -2px 4px #DCAB67"}}
          className={`bg-[#E7BB7E] py-1 rounded-2xl relative pl-10 pr-1 max-w-[75px] flex justify-end`}
        >
          <img src={src} alt="lamp_hint"
               className={`${iconSize} ${iconLeft} ${iconTop} drop-shadow -translate-y-1/2 box-border absolute`}/>
          <span className={`mr-1 font-bold text-stroke-chocolate text-[18px] text-white`}>x{count}</span>
        </div>

      </div>
      <div>
        <Button iconSize={20} onClick={onClick} className={`py-2 text-[19px] px-4 justify-end`} iconSpace={4}
                type={"ads"}>{innerWidth > 410 && `Смотреть`}</Button>
      </div>
    </div>
  )
}

export default AdvertisingPopout;