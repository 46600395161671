import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {
  AdvertisingRequest,
  AdvertisingResponse,
  InitRequest,
  InitResponse,
  LevelRequest,
  LevelResponse, RatingRequest,
  RatingResponse,
  TipRequest,
  TipResponse
} from "./RequestInterfaces";
import {ExternalPlatformController} from "../../utility/ExternalPlatformController/ExternalPlatformController";

const baseQuery = ExternalPlatformController.getBaseQuery();

const crosswordApi = createApi({
  reducerPath: "crosswordApi",
  baseQuery: baseQuery,
  endpoints: (build) => ({
    init: build.mutation<InitResponse, InitRequest>({
      query: (data) => ({
        url: "/init",
        body: data,
        method: "POST"
      }),
    }),
    level: build.mutation<LevelResponse, LevelRequest>({
      query: (data) => ({
        url: "/level",
        body : {
          payload : window.btoa(JSON.stringify(data))
        },
        method: "POST"
      }),
      // @ts-ignore
      transformResponse : (baseQueryReturnValue, meta, arg) => {
        return baseQueryReturnValue
      }
    }),
    help: build.mutation<TipResponse, TipRequest>({
      query: (data) => ({
        url: "/help",
        body : {
          payload : window.btoa(JSON.stringify(data))
        },
        method: "POST"
      })
    }),
    advertising: build.mutation<AdvertisingResponse, AdvertisingRequest>({
      query: (data) => ({
        url: "/adv",
        body : {
          payload : window.btoa(JSON.stringify(data))
        },
        method: "POST"
      })
    }),
    rating : build.query<RatingResponse, RatingRequest>({
      query : (data) => {
        return {
          url : "/rating",
          body : {
            payload : window.btoa(JSON.stringify(data))
          },
          method : "POST"
        }
      },
    })
  }),
})

export const {
  useInitMutation,
  useLevelMutation,
  useHelpMutation,
  useRatingQuery,
  useAdvertisingMutation
} = crosswordApi

export default crosswordApi;