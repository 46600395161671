import React, {ForwardedRef} from 'react';
import PNGs, {PreloadImages} from "../../../assets/images";
import {setRoute} from "../../../store/Slices/routerSlice";
import {useAppDispatch} from "../../../store/store";
import {setCurrentPopout} from "../../../store/Slices/popoutsSlice";
import FingerClick from "../../UI/Icons/FingerClickIcon";
import {motion} from "framer-motion";

interface ILevelButtonProps {
  part: number
  currentPart: number
  currentPartSection: number
}

const LevelButton = React.forwardRef(({
                                        part,
                                        currentPart,
                                        currentPartSection
                                      }: ILevelButtonProps, ref: ForwardedRef<HTMLImageElement>) => {
  let image;
  const dispatch = useAppDispatch()
  if (currentPart === part) {
    console.log("cp", currentPart, "cPS", currentPartSection);
    switch (currentPartSection) {
      case 0:
        image = PNGs.LevelButtonCurrent0
        break;
      case 1:
        image = PNGs.LevelButtonCurrent1
        break;
      case 2:
        image = PNGs.LevelButtonCurrent2
        break;
        case 3:
          image = PNGs.LevelButtonCurrent3
    }
  } else if (part < currentPart) {
    image = PNGs.LevelButtonCurrent4
  } else {
    image = PNGs.LevelButtonCurrent0
  }

  const onClick = () => {
    dispatch(setRoute({
      view : "default",
      panel : "game"
    }))
    dispatch(setCurrentPopout({
      name : "hidden"
    }))
  }

  return (
    <div className={`flex relative z-10 pl-[32px] pr-[54px] ${part % 2 === 0 ? "justify-start" : "justify-end"}`}>
      <div className={'relative min-h-[122px] h-fit w-fit'} onClick={currentPart === part ? onClick : undefined}>
        {
          part === currentPart ?
            <div className={`absolute -top-14 ${part % 2 === 0 ? "" : "-left-16"}`}>
              <div className={`relative`}>
                <img className={`${part % 2 === 0 ? "-scale-x-100" : ""}`} src={PNGs.DialogueSubstrate} alt=""/>
                <p
                  className={`absolute left-1/2 top-1/2 -translate-x-1/2 text-[24px] text-[#C67A47] font-bold -translate-y-9`}>
                  Начать!
                </p>
                <motion.img src={PreloadImages.Finger}
                            initial={{ scale: 1 }}
                            animate={{ scale: [1, 1.2, 1] }}
                            transition={{ duration: 1.5, repeat : Infinity }}
                            className={`w-[64px] pointer-events-none absolute -right-6 -bottom-3`} alt=""/>
              </div>
            </div>
            : null
        }
        <img src={image} alt=""/>
        <p className={'absolute top-[5px] left-1/2 -translate-x-1/2 text-[30px] font-medium'}>{part}</p>
      </div>
    </div>
  );
})

export default LevelButton;