import React, {useEffect, useRef, useState} from 'react';
import {
  AdaptivityProvider,
  AppRoot,
  ConfigProvider,
  ScreenSpinner,
  SplitCol,
  SplitLayout,
  View
} from '@vkontakte/vkui';
import '@vkontakte/vkui/dist/vkui.css';

import {Game} from "./components/panels";
import {useInitAppAssets} from "./hooks/useInitAppAssets";
import {TUserData, useInitExternalPlatform} from "./hooks/useInitExternalPlatform";
import {useAppDispatch, useAppSelector} from "./store/store";
import {
  claimRewards,
  selectCurrentConfig,
  selectCurrentIsInitialized,
  selectCurrentLocalLevel,
  selectCurrentServerData,
  selectCurrentUserData,
  setIsInitialized, setUser,
  updateServerLevel
} from "./store/Slices/userSlice";
import {useInitMutation, useLevelMutation} from "./store/APIs/crosswordApi";
import {selectCurrentPopoutName, setCurrentPopout, setGameIsHidden} from "./store/Slices/popoutsSlice";
import {
  selectCurrentCompletedWords,
  selectCurrentConnectionLetters,
  selectCurrentWords,
  setGameIsOver,
  updateConnectionLetters,
  updateCrossword
} from "./store/Slices/crosswordSlice";
import {prepareArrayToUpperCase} from "./utility/prepareArrayToUpperCase";
import {isDoubleDimensionArray} from "./typeguards/isDoubleDimensionArray";
import * as amplitude from "@amplitude/analytics-browser";
import {useHandleAndroidBackButton} from "./hooks/useHandleAndroidBack";
import {selectCurrentHistory} from "./store/Slices/routerSlice";
import Levels from "./components/panels/Levels/Levels";
import {updateLastLevelReward} from "./store/Slices/rewardSlice";
import sha256 from "./utility/sha256";
import {useRewards} from "./hooks/useRewards";
import {selectCurrentBannerHeight, setAvailableHeight, setBannerHeight} from "./store/Slices/adaptiveSlice";
import useVKAds from "./hooks/useVKAds";
import {InitRequest} from "./store/APIs/RequestInterfaces";
import {externalPlatformController} from "./index";
import {UtilityPopoutWrapper, UtilityWarningPopout} from "./components/widgets";

const App = () => {
  const currentHistory = useAppSelector(selectCurrentHistory)
  const currentRoute = currentHistory[currentHistory.length - 1];
  const currentView = currentRoute.view;
  const currentPanel = currentRoute.panel;
  const [ServerInitSuccess, setServerInitSuccess] = useState(false);
  const [initPopout, setInitPopout] = useState<null | React.ReactElement>(
    <div className={`fixed top-0 h-screen w-screen backdrop-blur-md`}>
      <ScreenSpinner size='large'/>
    </div>
  );

  const dispatch = useAppDispatch()
  const userData = useAppSelector(selectCurrentUserData)
  const serverData = useAppSelector(selectCurrentServerData)
  const [useInit, {data: initData, isSuccess: initSuccess, reset: resetInit}] = useInitMutation()
  const [useNextLevelMutation, {data: levelData, error: levelDataError, reset}] = useLevelMutation()
  const currentWords = useAppSelector(selectCurrentWords)
  const currentCompletedWords = useAppSelector(selectCurrentCompletedWords)
  const connectionLetters: string[] = useAppSelector(selectCurrentConnectionLetters)

  const currentConfig = useAppSelector(selectCurrentConfig)
  const currentLocalLevel = useAppSelector(selectCurrentLocalLevel)
  const {defaultReward} = useRewards({currentConfig, localLevel: currentLocalLevel})

  const currentUserData = useAppSelector(selectCurrentUserData)

  const isInitialized = useAppSelector(selectCurrentIsInitialized)

  useEffect(() => {
    if (initData) {
      setServerInitSuccess(true)
      dispatch(setIsInitialized(true))
    } else {
      setServerInitSuccess(false)
      dispatch(setIsInitialized(false))
    }
  }, [initData, initSuccess]);

  useEffect(() => {
    if (!serverData && userData && initSuccess) { // При ручном сбросе стора
      dispatch(setCurrentPopout({
        name: "start-screen"
      }))
      setInitPopout(
        <div className={`fixed top-0 h-screen w-screen backdrop-blur-md`}>
          <ScreenSpinner size='large'/>
        </div>
      )
      resetInit()
      useInit(userData as InitRequest)
    }
  }, [serverData]);

  const onExternalInitComplete = (userData: TUserData) => { // vk.ts Init
    amplitude.setUserId(String(userData.user_id))
    amplitude.track("Init")

    dispatch(setUser(userData))

    useInit(userData as InitRequest)
  }

  const {success: ExternalPlatformInitSuccess} = useInitExternalPlatform({onComplete: onExternalInitComplete})

  const onInitAssetsComplete = () => { // Assets Init

  }

  const {success: AssetsInitSuccess} = useInitAppAssets({onComplete: onInitAssetsComplete})

  useEffect(() => {
    if (!isInitialized) return;
    if (ExternalPlatformInitSuccess && ServerInitSuccess && AssetsInitSuccess && initData) {
      setInitPopout(null)
      externalPlatformController.updateLoadingProgress(100);
      if (initData.user.level === 1) {
        dispatch(setCurrentPopout({
          name: "hidden"
        })) //
        dispatch(setGameIsHidden(false))
      } else {
        dispatch(setCurrentPopout({
          name: "start-screen"
        }))
      }
      const upperCaseCrossword = prepareArrayToUpperCase(initData.crossword.body)
      const upperCaseConnectionLetters = prepareArrayToUpperCase(initData.crossword.letters)
      if (isDoubleDimensionArray(upperCaseCrossword)) {
        dispatch(updateCrossword(upperCaseCrossword))
      }
      if (isDoubleDimensionArray(upperCaseConnectionLetters)) {
        dispatch(updateConnectionLetters(upperCaseConnectionLetters))
      }
      dispatch(setIsInitialized(true))
    }
  }, [ExternalPlatformInitSuccess, ServerInitSuccess, AssetsInitSuccess, isInitialized]);

  useEffect(() => {
    if (levelDataError) {
      dispatch(setCurrentPopout({
        name: "error",
        props: {
          requestName: "levelChange"
        }
      }))
    }
  }, [levelDataError]);

  useEffect(() => {
    if (!levelData || !currentLocalLevel) return;
    if (currentLocalLevel === levelData.level) {
      if (currentLocalLevel === 2) {
        const query = {
          tutorial_step: 2
        }
        amplitude.track("Tutorial Step", query); // Нажал продолжить на окошке со сменой уровня
      } else if (currentLocalLevel === 3) {
        const query = {
          tutorial_step: 4
        }
        amplitude.track("Tutorial Step", query);
      }
      const upperCaseCrossword = prepareArrayToUpperCase(levelData.crossword.body)
      const upperCaseConnectionLetters = prepareArrayToUpperCase(levelData.crossword.letters)
      if (isDoubleDimensionArray(upperCaseCrossword)) {
        dispatch(updateCrossword(upperCaseCrossword))
      }
      if (isDoubleDimensionArray(upperCaseConnectionLetters)) {
        dispatch(updateConnectionLetters(upperCaseConnectionLetters))
      }
    } else {
      dispatch(setCurrentPopout({
        name: "level-changer"
      }))
      dispatch(claimRewards(defaultReward))
      dispatch(updateServerLevel(levelData.level))
      dispatch(updateLastLevelReward(levelData.reward))
    }
  }, [levelData, currentLocalLevel])

  useEffect(() => {
    if (!levelData) return;
    if (levelData.level === 2) {
      const query = {
        tutorial_step: 1
      }
      amplitude.track("Tutorial Step", query); // Завершил первый уровень
    } else if (levelData.level === 3) {
      const query = {
        tutorial_step: 3
      }
      amplitude.track("Tutorial Step", query); // Завершил второй уровень с подсказкой которая появляется раз в 6 секунд, если не получается
    }
    const query = {
      level_num: currentLocalLevel
    }
    amplitude.track("Complete Level", query)
  }, [levelData]);

  const onLevelComplete = async () => {
    if (!currentUserData || !currentLocalLevel) return;
    dispatch(setGameIsOver(true))
    const logs = await sha256(`${currentLocalLevel}${connectionLetters.join("")}`)
    const payload = {
      level: currentLocalLevel,
      user_id: String(currentUserData.user_id),
      logs: logs,
      platform: "vk"
    } as const
    useNextLevelMutation(payload)
  }

  useEffect(() => {
    if (!currentWords || !currentCompletedWords) return;
    const wordsInCrossword = Object.keys(currentWords).length
    const completedWords = currentCompletedWords
    if (wordsInCrossword === completedWords) {
      onLevelComplete()
    }
  }, [currentWords, currentCompletedWords]);

  useHandleAndroidBackButton()

  const viewRef = useRef<HTMLElement>(null)

  useEffect(() => {
    if (!viewRef.current) return;
    const resizeFn: ResizeObserverCallback = (entries, observer) => {
      const contentRect = entries[0].contentRect
      const availableHeight = contentRect.height;
      dispatch(setAvailableHeight(availableHeight))
    }

    const resizeObserver = new ResizeObserver(resizeFn);
    resizeObserver.observe(viewRef.current)

    return () => {
      resizeObserver.disconnect()
    }
  }, [viewRef]);

  const currentPopout = useAppSelector(selectCurrentPopoutName)
  const mobileVkRegExp = /m\.vk\.com/
  const isMobileVK = mobileVkRegExp.test(window.location.href)
  useEffect(() => {
    const stopSwipeBack = (e: TouchEvent) => {
      if (currentPopout !== "hidden") return;
      e.preventDefault()
    }
    if (isMobileVK) {
      window.addEventListener("touchmove", stopSwipeBack, {passive: false})
    }

    return () => {
      if (isMobileVK) {
        window.removeEventListener("touchmove", stopSwipeBack)
      }
    }
  }, [currentPopout]);

  useVKAds({VKInitSuccess: ExternalPlatformInitSuccess})

  const bannerHeight = useAppSelector(selectCurrentBannerHeight) ?? 0;

  return (
    <ConfigProvider transitionMotionEnabled={false}>
      <AdaptivityProvider>
        <AppRoot>
          <UtilityPopoutWrapper/>
          <UtilityWarningPopout/>
          <SplitLayout popout={initPopout}>
            <SplitCol>
              <View getRootRef={viewRef} style={{
                height: `calc(100vh - ${bannerHeight}px)`,
                padding: 0
              }} id={"default"} activePanel={currentPanel}>
                <Game id='game'/>
                <Levels id={"levels"}/>
              </View>
            </SplitCol>
          </SplitLayout>
        </AppRoot>
      </AdaptivityProvider>
    </ConfigProvider>
  );
}

export default App;
