import {Dispatch} from "redux";
import ExternalPlatformControllerInstance, {IOpenInterstitialAdResult} from "./abstract";
import {TUserData} from "../../../hooks/useInitExternalPlatform";

type Profile = {
  avatar: string,
  username: string,
  firstName: string,
  lastName: string,
  telegramId: number,
  locale: 'en' | 'ru',
  token: string, // You can read more about our jwt token https://github.com/ton-play/playdeck-integration-guide/wiki/4.--User-JWT
  params: { [key: string]: string }, // You can create a link with a query string to the game using the method customShare or getShareLink
  sessionId: string,
  currentGameStarted: number
};

export type TShowAdsPlaydeckMethods = TShowAdsPlaydeckSuccessMethods | TShowAdsPlaydeckFailedMethods
export type TShowAdsPlaydeckSuccessMethods = "rewardedAd"
export type TShowAdsPlaydeckFailedMethods = "errAd" | "skipAd" | "notFoundAd"

class PlaydeckPlatformControllerInstance implements ExternalPlatformControllerInstance {
  constructor() {
  }

  async init(dispatch: Dispatch) {
    const itHasParentWindow: Window | undefined = parent[0];
    if (!itHasParentWindow) {
      throw new Error("External window doesn't detected")
    }

    const userInfo = await this.getInitInfo()

    const result: TUserData = {
      platform: "playdeck", user_id: userInfo.user_id, name: userInfo.name, photo: userInfo.photo, token : userInfo.token
    }

    this.updateLoadingProgress(70);

    return result
  }

  async updateLoadingProgress(percentage : number) {
    const payload = {
      playdeck: {
        method: 'loading',
        value: percentage,
      },
    };

    parent.postMessage(payload, '*');
  }

  async getInitInfo(): Promise<TUserData> {
    return new Promise((res, rej) => {
      let gameIsLaunched = false
      let userInfo: Profile | undefined = undefined;

      const isInitialized = () => (!!(gameIsLaunched && userInfo))

      const receivePlayTrigger = ({data}: MessageEvent["data"]) => {
        if (!data || !data['playdeck']) return;

        if (data.playdeck.method === 'play' && !gameIsLaunched) {
          gameIsLaunched = true;
          parent.postMessage({playdeck: {method: 'getUserProfile'}}, '*');
        }
      }

      const receiveUserInfo = ({data}: MessageEvent["data"]) => {
        const playdeck = data?.playdeck;
        if (!playdeck) return;

        if (playdeck.method === 'getUserProfile') {
          userInfo = playdeck.value as Profile
          if (isInitialized()) {
            const result: TUserData = {
              user_id: String(userInfo.telegramId),
              name: `${userInfo.firstName} ${userInfo.lastName}`,
              platform: "playdeck",
              photo: userInfo.avatar,
              token: userInfo.token
            }

            res(result)
          }
        }
      }

      window.addEventListener("message", receivePlayTrigger)
      window.addEventListener("message", receiveUserInfo)
    })
  }

  async checkAdAvailable(): Promise<boolean> {
    return new Promise((res, rej) => {
      res(true)
    })
  }

  async showAd(): Promise<IOpenInterstitialAdResult> {
    return new Promise((res, rej) => {
      const sendAdsRequest = () => {
        const message = {
          playdeck: {
            method: "showAd",
            value : 1
          }
        }
        parent.postMessage(message, "*")
        console.log("отправил")
      }

      const receiveAdsResponse = ({data}: MessageEvent["data"]) => {
        const playdeck = data?.playdeck;
        if (!playdeck) return;

        const successMethods: TShowAdsPlaydeckSuccessMethods[] = ["rewardedAd"]
        const failedMethods: TShowAdsPlaydeckFailedMethods[] = ["errAd", "notFoundAd", "skipAd"]

        if (successMethods.includes(playdeck.method)) {
          res({result : true})
        } else if (failedMethods.includes(playdeck.method)) {
          res({result : false})
        }
      }

      window.addEventListener("message", receiveAdsResponse)
      sendAdsRequest()
    })
  }
}

export default PlaydeckPlatformControllerInstance;